<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="12">
                        <CButton
                            @click="$router.go(-1)"
                            size="sm"
                            color="warning" 
                        > 
                            <font-awesome-icon icon="arrow-left"/> Go back
                        </CButton>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="5">
                        <CCard>
                            <CCardHeader>
                                <font-awesome-icon icon="cog"/>  Manage Category
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>

                                <template v-if="document_template_category.status == 'Active'">
                                    <CButton 
                                        size="sm"
                                        color="danger" 
                                        class="float-right" 
                                        @click="toggleEdit(), returnInformation()"
                                        v-show="edit"
                                    >
                                        <font-awesome-icon icon="window-close"/> Cancel
                                    </CButton>
                                    <CButton 
                                        v-if="$store.getters['can']('update-document-template-category')"
                                        size="sm"
                                        :disabled="!isValid"
                                        color="success" 
                                        class="float-right" 
                                        v-show="edit" 
                                        @click="updateDocumentTemplateCategory()"
                                    >
                                        <font-awesome-icon icon="save"/> Update
                                    </CButton>
                                    <CButton 
                                        v-if="$store.getters['can']('edit-document-template-category')"
                                        size="sm"
                                        color="info" 
                                        class="float-right" 
                                        @click="toggleEdit" v-show="!edit"
                                    >
                                        <font-awesome-icon icon="edit"/> Edit
                                    </CButton>
                                </template>
                                <template v-if="document_template_category.status == 'Archived'">
                                    <CButton 
                                        v-if="$store.getters['can']('restore-document-template-category')"
                                        size="sm"
                                        color="success" 
                                        class="float-right" 
                                        @click="restore()"
                                    >
                                        <font-awesome-icon icon="trash-restore"/> Restore
                                    </CButton>
                                </template>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol lg="12">
                                        <h5>Status: <CBadge :color="getColorStatus(document_template_category.status)">{{document_template_category.status}}</CBadge></h5>
                                    </CCol>
                                </CRow>
                                <CRow> 
                                    <CCol lg="12"> 
                                        <label for="favcolor">Color</label> <label>{{ color }}</label>
                                            <CInput 
                                                :disabled="disable == 1? true : false"
                                                :lazy="false"
                                                :value.sync="$v.document_template_category.color.$model"
                                                :isValid="checkIfValid('color')"
                                                type="color" 
                                                value="#ff0000"
                                                v-model="document_template_category.color"  
                                            />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            :value.sync="$v.document_template_category.category.$model"
                                            :isValid="checkIfValid('category')"
                                            label="Name" 
                                            type="text" 
                                            autocomplete="off" 
                                            v-model="document_template_category.category"                                                                                                                   
                                            invalidFeedback="Must be three(3) characters." 
                                            v-tocapitalize
                                            v-nospecialcharacter                              
                                        />
                                    </CCol>
                                </CRow> 
                                <br v-show="edit">
                                
                            </CCardBody>
                        </CCard>
                        <span v-show="edit">
                            <CCard borderColor="danger" v-if="$store.getters['can']('archive-document-template-category')">
                                <CCardHeader>
                                    <font-awesome-icon icon="exclamation" :style="{ color: 'red' }"/> 
                                            Danger Zone
                                </CCardHeader>
                                <CCardBody>
                                    <CRow>
                                        <CCol lg="8">
                                            <h6>Archive this Category ?</h6>
                                        </CCol>
                                        <CCol lg="12">
                                            <label> Once archived, you cannot use this category anymore. Please be certain.</label>
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="3">
                                            <CButton shape="pill" color="warning" @click="archive()" > 
                                                <font-awesome-icon icon="minus-square" /> Archived 
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </span>
                    </CCol>
                    <CCol lg="7">
                        <template v-if="can_view_attachment_checklist">
                            <CCard>
                                <CCardHeader>
                                    <font-awesome-icon icon="link"/>
                                    Category Attachment Checklist
                                    <font-awesome-icon style="color: #4caf50;" icon="info-circle" size="lg"/>
                                    <CButton 
                                        :disabled="attachment_list.length > 0 || category_set_list.length > 0"
                                        size="sm"
                                        color="info" 
                                        @click="collapse_attachment = !collapse_attachment"
                                        class="float-right" 
                                    >
                                        <font-awesome-icon 
                                            :icon="collapse_attachment ? 'chevron-circle-down': 'chevron-circle-left'"
                                        />
                                    </CButton>
                                </CCardHeader>
                                <CCollapse :show="collapse_attachment">
                                    <CCardBody>
                                        <CRow>
                                            <CCol lg="12">
                                                <CAlert color="info">
                                                    <p-radio 
                                                        :disabled="!can_switch_attachment_type"
                                                        value="default"
                                                        class="p-default p-curve" 
                                                        name="color" 
                                                        color="danger-o"
                                                        v-model="selected_attachment_type">Use Default in Checklist
                                                    </p-radio>

                                                    <p-radio 
                                                        :disabled="!can_switch_attachment_type"
                                                        value="set"
                                                        class="p-default p-curve" 
                                                        name="color" 
                                                        color="danger-o"
                                                        v-model="selected_attachment_type">Use Set in Checklist
                                                    </p-radio>

                                                    <a-popover title="NOTE:" placement="topLeft">
                                                        <template slot="content" >
                                                        <p>Switching of attachment type is <strong>disabled</strong> until all entries <br>in the table have been <u>cleared</u>.</p>
                                                        </template>
                                                            <font-awesome-icon 
                                                                v-if="!can_switch_attachment_type"
                                                                style="color: #3399FF;" 
                                                                icon="info-circle" 
                                                                size="lg"
                                                            />
                                                    </a-popover>
                                                </CAlert>
                                            </CCol>
                                        </CRow>
                                        <template v-if="selected_attachment_type == 'default'">
                                            <template v-if="can_add_attachment_checklist">
                                                <CRow>
                                                    <CCol lg="4">
                                                        Name
                                                    </CCol>
                                                    <CCol lg="4">
                                                        Description
                                                    </CCol>
                                                    <CCol lg="4">
                                                        Required <a-popover title="NOTE:" placement="topRight">
                                                            <template slot="content" >
                                                            <p>When the attachment checklist is required, saving or starting <br>document routing is not possible without it.</p>
                                                            </template>
                                                                <font-awesome-icon style="color: #4caf50; " icon="info-circle"/>
                                                        </a-popover>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol lg="4">
                                                        <CInput 
                                                            :class="!attachment.name ? 'has-inp-error' : 'has-inp-success'"
                                                            placeholder="PO Document"
                                                            type="text" 
                                                            autocomplete="off" 
                                                            v-model="attachment.name"
                                                        >
                                                        </CInput>
                                                    </CCol>
                                                    <CCol lg="4">
                                                        <CTextarea
                                                            type="text" 
                                                            placeholder="Enter brief description..." 
                                                            autocomplete="off"  
                                                            class="grp-text-area"
                                                            v-model="attachment.description"
                                                        />
                                                    </CCol>
                                                    <CCol lg="2">
                                                        <CSwitch 
                                                            color="success" 
                                                            variant="opposite" 
                                                            :checked.sync="attachment.required"
                                                            v-bind="labelTxt"  
                                                        />
                                                    </CCol>
                                                    <CCol lg="2">
                                                        <CButton
                                                            :disabled="!attachment.name || attachment_adding"
                                                            class="float-right"
                                                            color="success"
                                                            @click="attachment.name ? addAttachmentChecklist() : ''"
                                                            :title="attachment.name ? 'Add Attachment Checklist.' : 'Attachment cannot be empty.'"
                                                        >
                                                        <!-- <font-awesome-icon icon="plus"/> -->
                                                        
                                                        <font-awesome-icon :icon="attachment_adding ? 'circle-notch' : 'plus'" 
                                                                    :pulse="attachment_adding"/>
                                                        </CButton>
                                                    </CCol>
                                                </CRow>
                                            </template>
                                            <CRow>
                                                <CCol lg="12">
                                                    <CDataTable
                                                            :key="attachment_key"
                                                            :items="attachment_list"
                                                            :fields="attachment_field_list"
                                                            :items-per-page="10"
                                                            border
                                                            sorter
                                                        >
                                                        <template #name={item}>
                                                            <td>
                                                                <template v-if="can_update_attachment_checklist">
                                                                    <CInput 
                                                                        :class="!item.name ? 'has-inp-error' : 'has-inp-success'"
                                                                        placeholder="PO Document"
                                                                        type="text" 
                                                                        autocomplete="off" 
                                                                        v-model="item.name"
                                                                    >
                                                                    </CInput>
                                                                </template>
                                                                <template v-else>
                                                                    {{ item.name }}
                                                                </template>
                                                            </td>
                                                        </template>
                                                        <template #description="{item}">
                                                            <td>
                                                                <template v-if="can_update_attachment_checklist">
                                                                    <CTextarea
                                                                        type="text" 
                                                                        placeholder="Enter brief description..." 
                                                                        autocomplete="off"  
                                                                        class="grp-text-area"
                                                                        v-model="item.description"
                                                                    />
                                                                </template>
                                                                <template v-else>
                                                                    {{ item.description }}
                                                                </template>
                                                            </td>
                                                        </template>
                                                        <template #required={item}>
                                                            <td>
                                                                <template v-if="can_update_attachment_checklist">
                                                                    <CSwitch 
                                                                        color="success" 
                                                                        variant="opposite" 
                                                                        :checked.sync="item.required"
                                                                        v-bind="labelTxt"  
                                                                    />
                                                                </template>
                                                                <template v-else>
                                                                    <CBadge :color="getColorStatus(item.required)">
                                                                        {{ item.required ? 'Required' : 'Not Required'}}
                                                                    </CBadge>
                                                                </template>
                                                            </td>
    
                                                        </template>
                                                        
                                                
                                                        <template #action="{item, index}">
                                                            <td>
                                                                <center>
                                                                    <template v-if="can_update_attachment_checklist">
                                                                        <CButton 
                                                                            v-if="item.required != item.required_backup || item.name != item.name_backup || item.description != item.description_backup"
                                                                            :disabled="is_updating_attachment && selected_attachment_id == item.id"
                                                                            color="warning" 
                                                                            title="Update Attachment"
                                                                            shape="pill" 
                                                                            size="sm"
                                                                            @click="updateAttachmentChecklist(attachment_list, item, index)"
                                                                        >
                                                                            <font-awesome-icon :icon="is_updating_attachment && selected_attachment_id == item.id ? 'circle-notch' : 'pen-to-square'" 
                                                                            :pulse="is_updating_attachment && selected_attachment_id == item.id"/>
                                                                        </CButton>
                                                                    </template>
                                                                    &nbsp;
                                                                    <template v-if="can_remove_attachment_checklist">
                                                                        <CButton 
                                                                            :disabled="is_removing_attachment && selected_attachment_id == item.id"
                                                                            color="danger" 
                                                                            shape="pill" 
                                                                            title="Remove Attachment"
                                                                            size="sm"
                                                                            @click="removeAttachmentChecklist(item, index)"
                                                                        >
                                                                            <font-awesome-icon :icon="is_removing_attachment && selected_attachment_id == item.id ? 'circle-notch' : 'times'" 
                                                                            :pulse="is_removing_attachment && selected_attachment_id == item.id"/>
                                                                        </CButton>
                                                                    </template>
                                                                </center>
                                                            </td>
                                                        </template>
                                                    </CDataTable>
                                                </CCol>
                                            </CRow> 
                                        </template>
                                        <template v-else>
                                            <template v-if="can_add_attachment_checklist">
                                                <CRow>
                                                    <CCol lg="4">
                                                        Set Name
                                                    </CCol>
                                                    <CCol lg="4">
                                                        Description
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol lg="4">
                                                        <CInput 
                                                            placeholder="Re Invoice Set 1"
                                                            type="text" 
                                                            autocomplete="off" 
                                                            v-model="new_set.name"
                                                        >
                                                        </CInput>
                                                    </CCol>
                                                    <CCol lg="4">
                                                        <CTextarea
                                                            type="text" 
                                                            placeholder="Enter brief description..." 
                                                            autocomplete="off"  
                                                            class="grp-text-area"
                                                            v-model="new_set.description"
                                                        />
                                                    </CCol>
                                                    <CCol lg="3">
                                                        <CButton
                                                            :disabled="!new_set.name"
                                                            color="warning"
                                                            @click="showAttachmentChecklistModal(new_set, true)"
                                                        >
                                                        Attachment Checklists ( {{ new_set.attachment_checklist.length }} )
                                                        </CButton>
                                                    </CCol>
                                                    <CCol lg="1">
                                                        <CButton
                                                            :disabled="adding_new_set || !new_set.name || new_set.attachment_checklist.length == 0"
                                                            color="success"
                                                            @click="addNewSet()"
                                                        >
                                                            <font-awesome-icon :icon="!adding_new_set ? 'plus' : 'circle-notch'"
                                                            :pulse="adding_new_set"/>
                                                        </CButton>
                                                    </CCol>
                                                </CRow>
                                            </template>
                                            <CRow>
                                                <CCol lg="12">
                                                    <CDataTable
                                                            :key="category_set_key"
                                                            :items="category_set_list"
                                                            :fields="category_set_field_list"
                                                            :items-per-page="10"
                                                            size="sm"
                                                            border
                                                            sorter
                                                        >
                                                        <template #attachment_checklist={item}>
                                                            <td>
                                                                <center>
                                                                    <CButton
                                                                        color="warning"
                                                                        @click="showAttachmentChecklistModal(item, false)"
                                                                    >
                                                                        Attachments Checklist ( {{ item.attachment_checklist.length }} )
                                                                    </CButton>
                                                                </center>
                                                            </td>
                                                        </template>
                                                        <template #description="{item}">
                                                            <td>
                                                                <template v-if="can_update_attachment_checklist">
                                                                    <CTextarea
                                                                        type="text" 
                                                                        placeholder="Enter brief description..." 
                                                                        autocomplete="off"  
                                                                        class="grp-text-area"
                                                                        v-model="item.description"
                                                                    />
                                                                </template>
                                                                <template v-else>
                                                                    {{ item.description }}
                                                                </template>
                                                            </td>
                                                        </template>
                                                        
                                                        <template #name={item}>
                                                            <td>
                                                                <template v-if="can_update_attachment_checklist">
                                                                    <CInput 
                                                                        :class="!item.name ? 'has-inp-error' : 'has-inp-success'"
                                                                        placeholder="PO Document"
                                                                        type="text" 
                                                                        autocomplete="off" 
                                                                        v-model="item.name"
                                                                    >
                                                                    </CInput>
                                                                </template>
                                                                <template v-else>
                                                                    {{ item.name }}
                                                                </template>
                                                            </td>
                                                        </template>
                                                        <template #action="{item, index}">
                                                            <td>
                                                                <center>
                                                                    <template v-if="can_update_attachment_checklist">
                                                                        <CButton 
                                                                            v-if=" item.name != item.name_backup || item.description != item.description_backup"
                                                                            :disabled="is_updating_attachment && selected_attachment_id == item.id"
                                                                            color="warning" 
                                                                            title="Update Attachment"
                                                                            shape="pill" 
                                                                            size="sm"
                                                                            @click="updateCategorySet(item, index)"
                                                                        >
                                                                            <font-awesome-icon :icon="is_updating_attachment && selected_attachment_id == item.id ? 'circle-notch' : 'pen-to-square'" 
                                                                            :pulse="is_updating_attachment && selected_attachment_id == item.id"/>
                                                                        </CButton>
                                                                    </template>
                                                                    &nbsp;
                                                                    <CButton 
                                                                        :disabled="is_removing_set && selected_set_id_to_remove == item.id"
                                                                        color="danger" 
                                                                        shape="pill" 
                                                                        size="sm"
                                                                        @click="removeCategorySet(item, index)"
                                                                    >
                                                                        <font-awesome-icon :icon="is_removing_attachment_in_set && selected_attachment_id_in_set == item.id ? 'circle-notch' : 'times'" 
                                                                            :pulse="is_removing_attachment_in_set && selected_attachment_id_in_set == item.id"/>
                                                                    </CButton>
                                                                </center>
                                                            </td>
                                                        </template>
                                                    </CDataTable>
                                                </CCol>
                                            </CRow> 
                                        </template>
                                    </CCardBody>
                                </CCollapse>
                            </CCard>
                        </template>
                    </CCol>
                </CRow>
                <CModal
                    :show.sync="attachment_checklists_modal"
                    color="primary"
                    :closeOnBackdrop="false"
                    size="lg"
                    >
                    <template #header>
                        <h6> <font-awesome-icon icon="comment-dots"/> Attachment Checklists for {{selected_set?.name}} </h6>
                        <CButton 
                            class="pull-right" 
                            color="light" 
                            shape="pill" 
                            size="sm"
                            @click="attachment_checklists_modal = false"
                        >
                            <font-awesome-icon  size="sm" icon="times"/>
                        </CButton>
                    </template>
                    <template #footer>
                        <CRow lg="12">
                            <CButton 
                                class="pull-right" 
                                color="primary" 
                                @click="attachment_checklists_modal = false"
                            >
                                Close
                            </CButton>
                        </CRow>
                    </template>
                    <template #body-wrapper>
                        <CCardBody>
                            <small v-if="selected_set?.id">DTCS_ID: <strong>{{selected_set.id}}</strong></small>
                            <br v-if="selected_set?.id">
                            
                            <template v-if="can_add_attachment_checklist">
                                <CRow>
                                    <CCol lg="4">
                                        Name
                                    </CCol>
                                    <CCol lg="4">
                                        Description
                                    </CCol>
                                    <CCol lg="4">
                                        Required <a-popover title="NOTE:" placement="topRight">
                                            <template slot="content" >
                                            <p>When the attachment checklist is required, saving or starting <br>document routing is not possible without it.</p>
                                            </template>
                                                <font-awesome-icon style="color: #4caf50; " icon="info-circle"/>
                                        </a-popover>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="4">
                                        <CInput 
                                            placeholder="PO Document"
                                            type="text" 
                                            autocomplete="off" 
                                            v-model="new_checklist.name"
                                        >
                                        </CInput>
                                    </CCol>
                                    <CCol lg="4">
                                        <CTextarea
                                            type="text" 
                                            placeholder="Enter brief description..." 
                                            autocomplete="off"  
                                            class="grp-text-area"
                                            v-model="new_checklist.description"
                                        />
                                    </CCol>
                                    <CCol lg="2">
                                        <CSwitch 
                                            color="success" 
                                            variant="opposite" 
                                            v-bind="labelTxt"  
                                            :checked.sync="new_checklist.required"
                                        />
                                    </CCol>
                                    <CCol lg="2">
                                        <CButton
                                            :disabled="!new_checklist.name || attachment_adding"
                                            color="success"
                                            @click="addNewChecklist()"
                                        >
                                        <font-awesome-icon :icon="attachment_adding ? 'circle-notch' : 'plus'" 
                                                    :pulse="attachment_adding"/>
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </template>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable
                                            :key="attachment_checklist_key"
                                            :items="attachment_checklist_list"
                                            :fields="attachment_checklist_field_list"
                                            :items-per-page="10"
                                            size="sm"
                                            border
                                            sorter
                                        >
                                        <template #required={item}>
                                            <td>
                                                <template v-if="can_update_attachment_checklist">
                                                    <CSwitch 
                                                        color="success" 
                                                        variant="opposite" 
                                                        :checked.sync="item.required"
                                                        v-bind="labelTxt"  
                                                    />
                                                </template>
                                                <template v-else>
                                                    <CBadge :color="getColorStatus(item.required)">
                                                        {{ item.required ? 'Required' : 'Not Required'}}
                                                    </CBadge>
                                                </template>
                                            </td>
                                        </template>
                                        <template #name={item}>
                                            <td>
                                                <template v-if="can_update_attachment_checklist">
                                                    <CInput 
                                                        :class="!item.name ? 'has-inp-error' : 'has-inp-success'"
                                                        placeholder="PO Document"
                                                        type="text" 
                                                        autocomplete="off" 
                                                        v-model="item.name"
                                                    >
                                                    </CInput>
                                                </template>
                                                <template v-else>
                                                    {{ item.name }}
                                                </template>
                                            </td>
                                        </template>
                                        <template #description="{item}">
                                            <td>
                                                <template v-if="can_update_attachment_checklist">
                                                    <CTextarea
                                                        type="text" 
                                                        placeholder="Enter brief description..." 
                                                        autocomplete="off"  
                                                        class="grp-text-area"
                                                        v-model="item.description"
                                                    />
                                                </template>
                                                <template v-else>
                                                    {{ item.description }}
                                                </template>
                                            </td>
                                        </template>
                                        <template #action="{item, index}">
                                            <td>
                                                <center>
                                                    <template v-if="can_update_attachment_checklist">
                                                        <CButton 
                                                            v-if="!is_new_set && (item.required != item.required_backup || item.name != item.name_backup || item.description != item.description_backup)"
                                                            :disabled="is_updating_attachment && selected_attachment_id == item.id"
                                                            color="warning" 
                                                            title="Update Attachment"
                                                            shape="pill" 
                                                            size="sm"
                                                            @click="updateAttachmentChecklist(attachment_checklist_list, item, index)"
                                                        >
                                                            <font-awesome-icon :icon="is_updating_attachment && selected_attachment_id == item.id ? 'circle-notch' : 'pen-to-square'" 
                                                            :pulse="is_updating_attachment && selected_attachment_id == item.id"/>
                                                        </CButton>
                                                    </template>
                                                    &nbsp;
                                                    <!-- :disabled="attachment_checklist_list.length == 1" -->
                                                    <CButton 
                                                        :disabled="is_removing_attachment_in_set && selected_attachment_id_in_set == item.id"
                                                        color="danger" 
                                                        shape="pill" 
                                                        title="Remove Attachment"
                                                        size="sm"
                                                        @click="removeAttachmentChecklistInSet(item, index)"
                                                    >
                                                        <font-awesome-icon :icon="is_removing_attachment_in_set && selected_attachment_id_in_set == item.id ? 'circle-notch' : 'times'" 
                                                        :pulse="is_removing_attachment_in_set && selected_attachment_id_in_set == item.id"/>
                                                    </CButton>
                                                </center>
                                            </td>
                                        </template>
                                    </CDataTable>
                                </CCol>
                            </CRow> 
                        </CCardBody>
                    </template>
                </CModal>
            </div>
        </div>
    </div>
</template>
<script>
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'DocumentTemplateCategoryEdit',
    data() {
        return {    
            category: null,
            color: null,
            disable: 1,
            edit: false,


            attachment: {
                name: null,
                required: true,
            },

            attachment_adding: false,
            
            selected_attachment_id: null,
            attachment_list: [],
            labelTxt: {
                labelOn: 'Yes',
                labelOff: 'No'
            },
            checklist_id_to_remove: [],
            attachment_key: 0,
            collapse_attachment: true, 
            is_updating_attachment: false,
            is_removing_attachment: false,
            is_removing_attachment_in_set: false,
            selected_attachment_id_in_set: null,
            is_removing_set: false,
            selected_set_id_to_remove: null,

            document_template_category: { 
                id: this.$route.params.id, 
                category: null,
                color: null,
                status: null,
            },
            document_template_category_backup: {
                id: this.$route.params.id,
                category: null,
                color: null,
                status: null,
            },

            selected_attachment_type: null,

            new_set: {
                name: null,
                description: null,
                attachment_checklist: []
            },

            is_new_set: false,
            adding_new_set: false,

            new_checklist: {
                name: null,
                description: null,
                required: false,
            },

            selected_set: null,

            category_set_key: 0,
            category_set_list: [
                // { name: 'Set1', description: 'Ikaw matalino', attachment_checklist:[
                //     {name: 'PO routing', description: 'sample', required: true},
                //     {name: 'JO routing', description: 'description', required: true},
                //     {name: 'PI routing', description: null, required: false},
                // ] },
                // { name: 'Set2', description: 'Ikaw bobo', attachment_checklist:[
                //     {name: 'PO routing', description: null, required: false},
                //     {name: 'PI routing', description: 'description1', required: true},
                // ] },
                // { name: 'Set3', description: 'Ikaw utak talaba', attachment_checklist:[
                //     {name: 'PO routing', description: null, required: false},
                //     {name: 'PI routing', description: null, required: false},
                // ] },
                // { name: 'Set4', description: 'Imong mama', attachment_checklist:[
                //     {name: 'PO routing', description: 'sample', required: true},
                //     {name: 'JO routing', description: 'description', required: true},
                //     {name: 'PI routing', description: null, required: true},
                // ] },
            ],
            attachment_checklists_modal: false,

            attachment_checklist_key: 0,
        }
    },
    computed: {
        isValid () { return !this.$v.document_template_category.$invalid },
        isDirty () { return this.$v.document_template_category.$anyDirty },
        attachment_checklist_list: function () {
            return this.selected_set?.attachment_checklist;
        },
        attachment_list_modified: function () {
            return this.attachment_list.map(p =>
                parseInt(p.required) == 1 ? { ...p, required: true, 
                                                    required_backup: true,
                                                    name_backup: p.name
                                            } : 
                                            { ...p, required: false, 
                                                    required_backup: false,
                                                    name_backup: p.name
                                            }
            )
        },
        attachment_checklist_field_list: function () {
            let custom_fields= [ 
                { key: 'name',},
                { key: 'description',},
                { key: 'required' },
            ]
            if(this.can_remove_attachment_checklist || this.can_update_attachment_checklist) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },
        category_set_field_list: function () {
            let custom_fields= [ 
                { key: 'name',},
                { key: 'description',},
                { key: 'attachment_checklist' },
            ]
            if(this.can_remove_attachment_checklist || this.can_update_attachment_checklist) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },
        attachment_field_list : function() {
            let custom_fields= [
                { key: 'name',},
                { key: 'description',},
                { key: 'required' },
            ]
            if(this.can_remove_attachment_checklist || this.can_update_attachment_checklist) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },
        can_view_attachment_checklist: function () {
            return this.$store.getters['can']('document-template-category-view-attachment-checklist')
        },
        can_add_attachment_checklist: function () {
            return this.$store.getters['can']('document-template-category-add-attachment-checklist')
        },
        can_remove_attachment_checklist: function () {
            return this.$store.getters['can']('document-template-category-remove-attachment-checklist')
        },
        can_update_attachment_checklist: function () {
            return this.$store.getters['can']('document-template-category-update-attachment-checklist')
        },
        can_switch_attachment_type: function () {
            if(this.attachment_list.length > 0 || this.category_set_list.length > 0) {
                return false;
            } return true;
        }
    },
    validations: {
        document_template_category: {
            category: { required, minLength: minLength(3)  },
            color: { required, },
        }
    },
    created() {
        this.document_template_category.id = this.$route.params.id  
        this.$Progress.start()
        this.getDocumentTemplateCategory();
        this.$Progress.finish()
    },
    methods: {
        addNewSet: function () {
            this.category_set_key++;
            this.adding_new_set = true;
            let data = {
                adding_new_set: true,
                name: this.new_set.name,
                document_template_category_id: this.document_template_category.id,
                description: this.new_set.description,
                attachment_checklist: this.new_set.attachment_checklist
            }

            axios.post('/drs/document-template-category-attachment-checklist/add', data, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200) {
                    
                    let resp = response.data.data;
                    let object = {
                        id: resp.id,
                        name: resp.name,
                        name_backup: resp.name,
                        description: resp.description ? resp.description : '',
                        description_backup: resp.description ? resp.description : '',
                    }

                    let temp_attachment_checklist = []; 
                    for (let index = 0; index < resp.dtcsac.length; index++) {
                        const element = resp.dtcsac[index];
                        let sub_row = {
                            id: element.id,
                            name: element.name,
                            name_backup: element.name,
                            description: element.description ? element.description : '',
                            description_backup: element.description ? element.description : '',
                            required: element.required,
                            required_backup: element.required,
                        }
                        temp_attachment_checklist.push(sub_row);
                    }
                    object.attachment_checklist = temp_attachment_checklist;

                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${object.name} has been added.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })

                    this.category_set_list.push(object);
                    this.new_set.name = null
                    this.new_set.description = null
                    this.new_set.attachment_checklist = []
                } 
                this.adding_new_set = false
            })
        },
        addNewChecklist: function () {
            if(!this.attachment_adding) {
                this.attachment_adding = true
                let row;
                let data = {
                    dtcs_id: this.selected_set.id,
                    attachment_type: this.selected_attachment_type,
                    name: this.new_checklist.name,
                    description: this.new_checklist.description ? this.new_checklist.description : '',
                    required: this.new_checklist.required,
                }
                if (data.dtcs_id) { // added in set that is already been save in the table
                    axios.post('/drs/document-template-category-attachment-checklist/add', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200) {
                            let resp = response.data.data;
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `${resp.name} has been added.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            //ivan add
                            row = {
                                id: resp.id,
                                name: resp.name, 
                                description: resp.description ? resp.description : '', 
                                required: resp.required,
                                // ----
                                name_backup: resp.name, 
                                description_backup: resp.description ? resp.description : '', 
                                required_backup: resp.required
                            }
                            this.selected_set.attachment_checklist.push(row)
                        } 
                    })
                } else { // added in set that is not yet save in the table.
                    row = {
                        name: data.name, 
                        description: data.description ? data.description : '', 
                        required: data.required,
                        // ----
                        name_backup: data.name, 
                        description_backup: data.description ? data.description : '', 
                        required_backup: data.required
                    }
                    this.selected_set.attachment_checklist.push(row)
                }
                
                this.attachment_adding = false
                this.new_checklist.name = '';
                this.new_checklist.description = '';
                this.new_checklist.required = false;
            }
        },
        showAttachmentChecklistModal: function (data, is_adding_new_set=false) {
            this.attachment_checklists_modal = true;
            this.attachment_checklist_key++;
            this.selected_set = data
            this.is_new_set = is_adding_new_set;
        },  
        updateAttachmentChecklist: function (array, item, ind) {
            this.selected_attachment_id = item.id;
            this.attachment_key++;
            this.attachment_checklist_key++;
            this.is_updating_attachment = true;
            let data = {
                attachment_type: 'default', // work around by genesis tag the attachment checklist even if the attachment type is 'set'
                dtcsac_id: item.id,
                name: item.name,
                description: item.description,
                required: item.required
            }
            axios.post('/drs/document-template-category-attachment-checklist/update', data, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200) {
                    array[ind]['name_backup'] = data.name
                    array[ind]['description_backup'] = data.description
                    array[ind]['required_backup'] = data.required                 
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been updated.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
                this.is_updating_attachment = false;
            })
        },
        removeAttachmentChecklistInSet: function (item, ind) {
            if(!item.id) {
                this.selected_set.attachment_checklist.splice(ind, 1)
            } else {
                this.is_removing_attachment_in_set = true;
                this.selected_attachment_id_in_set = item.id
                let data = {
                    dtcsac_id: item.id,
                }
                this.destroy(data).then(response => {
                    if ( response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            icon: 'success',
                            title: `${item.name} has been removed.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        this.selected_set.attachment_checklist.splice(ind, 1);
                    }
                    this.is_removing_attachment_in_set = false;
                })
            }
        },
        removeAttachmentChecklist: function (item, ind) {
            this.attachment_key++;
            this.selected_attachment_id = item.id;
            this.is_removing_attachment = true;
            let data = {
                dtcsac_id: item.id,
            }
            return this.destroy(data).then(response => {
                if ( response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been removed.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.attachment_list.splice(ind, 1);
                }
                this.is_removing_attachment = false;
            })
        },
        destroy: function (data) {
            return new Promise((resolve, reject) => {
                axios.post('/drs/document-template-category-attachment-checklist/remove', data, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        addAttachmentChecklist: function () {
            this.attachment_key++;
            if(!this.attachment_adding) {
                this.attachment_adding = true
                let data = {
                    dtc_id: this.document_template_category.id,
                    attachment_type: this.selected_attachment_type,
                    name: this.attachment.name,
                    description: this.attachment.description,
                    required: this.attachment.required,
                }
                axios.post('/drs/document-template-category-attachment-checklist/add', data, {validateStatus: () => true})
                .then(response => {
                    if ( response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            icon: 'success',
                            title: `${this.attachment.name} has been added.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        //ivan add
                        let row = {
                            id: response.data.data.id, 
                            name: this.attachment.name, 
                            name_backup: this.attachment.name, 
                            description: this.attachment.description ? this.attachment.description : '', 
                            description_backup: this.attachment.description ? this.attachment.description : '', 
                            required: this.attachment.required ? true : false,
                            required_backup: this.attachment.required ? true : false
                        }
                        this.attachment_list.push(row)
                        this.attachment.name = ''
                        this.attachment.description = ''
                        this.attachment.required = true;
                        this.attachment_adding = false
                    } else {
                        this.attachment_adding = false
                    }
                })
            }
        },
        updateCategorySet: function (item, ind) {
            this.selected_attachment_id = item.id;
            this.category_set_key++;
            this.is_updating_attachment = true;
            
            let data = {
                attachment_type: this.selected_attachment_type,
                dtcs_id: item.id,
                name: item.name,
                description: item.description,
            }
            axios.post('/drs/document-template-category-attachment-checklist/update', data, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200) {
                    this.category_set_list[ind]['name_backup'] = data.name
                    this.category_set_list[ind]['description_backup'] = data.description
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been updated.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
                this.is_updating_attachment = false;
            })
        },
        removeCategorySet: function (item, ind) {
            this.category_set_key++;
            this.selected_set_id_to_remove = item.id;
            this.is_removing_set = true;
            let data = {
                document_template_category_set_id: item.id,
            }
            return this.destroy(data).then(response => {
                if ( response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been removed.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.category_set_list.splice(ind, 1);
                }
                this.is_removing_set = false;
            })
        },
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.document_template_category[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.validate();
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            this.document_template_category = Vue.util.extend({}, this.document_template_category_backup)
        },
        getDocumentTemplateCategory: function() { //(list)
            axios.get('drs/document-template-category/' + this.document_template_category.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.assignData(response.data)
                }
            })
        },
        assignData: function(response){
            this.document_template_category = response.data;
            if(this.document_template_category.document_template_category_set.length > 0
                && this.document_template_category.document_template_category_set_attachment_checklist.length == 0
            ) {
                this.selected_attachment_type = 'set';
            } else if (this.document_template_category.document_template_category_set_attachment_checklist.length > 0
            && this.document_template_category.document_template_category_set.length == 0
            ) {
                this.selected_attachment_type = 'default';
            } else {
                this.collapse_attachment = false;
                this.selected_attachment_type = 'default';
            }
            let attachment_checklist = response.data.document_template_category_set_attachment_checklist;
            let category_checklist = response.data.document_template_category_set;
            if(attachment_checklist.length > 0) {
                for (let index = 0; index < attachment_checklist.length; index++) {
                    const el = attachment_checklist[index];
                    let row = {
                        id: el.id,
                        name: el.name,
                        name_backup: el.name,
                        description: el.description ? el.description : '',
                        description_backup: el.description ? el.description : '',
                        required: parseInt(el.required) ?  true : false,
                        required_backup: parseInt(el.required) ?  true : false,
                    }
                    this.attachment_list.push(row)
                }
            }

            if(category_checklist.length > 0) {
                for (let index = 0; index < category_checklist.length; index++) {
                    const el = category_checklist[index];
                    let sub_el = el.document_template_category_set_attachment_checklist
                    let row = {
                        id: el.id,
                        name: el.name,
                        name_backup: el.name,
                        description: el.description ? el.description : '',
                        description_backup: el.description ? el.description : '',

                        // required: parseInt(el.required) ?  true : false,
                        // required_backup: parseInt(el.required) ?  true : false,
                    }
                    let sub_row = [];

                    for (let index = 0; index < sub_el.length; index++) {
                        const element = sub_el[index];
                        sub_row.push({
                            id: element.id,
                            name: element.name,
                            name_backup: element.name,
                            description: element.description ? element.description : '',
                            description_backup: element.description ? element.description : '',
                            required: parseInt(element.required) ?  true : false,
                            required_backup: parseInt(element.required) ?  true : false,
                        })
                    }
                    row.attachment_checklist = sub_row;


                    // attachment_checklist: el.document_template_category_set_attachment_checklist

                    this.category_set_list.push(row)
                }
            }





            // category_set_list: [
                // { name: 'Set1', description: 'Ikaw matalino', attachment_checklist:[
                //     {name: 'PO routing', description: 'sample', required: true},
                //     {name: 'JO routing', description: 'description', required: true},
                //     {name: 'PI routing', description: null, required: false},
                // ] },
                // { name: 'Set2', description: 'Ikaw bobo', attachment_checklist:[
                //     {name: 'PO routing', description: null, required: false},
                //     {name: 'PI routing', description: 'description1', required: true},
                // ] },
                // { name: 'Set3', description: 'Ikaw utak talaba', attachment_checklist:[
                //     {name: 'PO routing', description: null, required: false},
                //     {name: 'PI routing', description: null, required: false},
                // ] },
                // { name: 'Set4', description: 'Imong mama', attachment_checklist:[
                //     {name: 'PO routing', description: 'sample', required: true},
                //     {name: 'JO routing', description: 'description', required: true},
                //     {name: 'PI routing', description: null, required: true},
                // ] },
            // ],

            this.document_template_category.status = response.data.deleted_at ? 'Archived' : 'Active';
            this.document_template_category_backup = Vue.util.extend({}, response.data);
        },
        validateDocumentTemplateCategory:function () {
            if( !this.document_template_category.category || !this.document_template_category.color) {
                return false;
            } 
            return true;
        },
        archive: function() {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.document_template_category.category}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('drs/document-template-category/archive/' + this.document_template_category.id, {validateStatus: () => true})
                        .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.document_template_category.category} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.document_template_category.status = 'Archived'
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('drs/document-template-category/restore/' + this.document_template_category.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.document_template_category.category} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.document_template_category = Vue.util.extend({}, this.document_template_category_backup)
                    this.document_template_category.status = 'Active'
                    this.$Progress.finish() 
                }
            })
        },
        updateDocumentTemplateCategory: function () {
            if(JSON.stringify(this.document_template_category_backup) == JSON.stringify(this.document_template_category)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            if(this.validateDocumentTemplateCategory()) {
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update the category.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        let data= {
                            category: this.document_template_category.category,
                            color: this.document_template_category.color,
                        }
                        return axios.post('drs/document-template-category/update/' + this.document_template_category.id, data, {validateStatus: () => true})
                        .then(response => {
                            if( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.document_template_category.category} has been updated.`,
                                }).then(() => {
                                    this.assignData(response.data)
                                    this.toggleEdit()
                                    this.$v.$reset()
                                    this.$v.$touch()
                                    this.$Progress.finish()
                                })
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            }) 
            return;
        },
        update: function () {
            this.$Progress.start()
            let data= {
                category: this.document_template_category.category,
                color: this.document_template_category.color,
            }
            axios.post('drs/document-template-category/update/' + this.document_template_category.id, data, {validateStatus: () => true})
            .then(response => {
                if( response.status == 200 ) {
                    this.$swal({
                        icon: "success",
                        title: "Success!",
                        text: `${this.document_template_category.category} has been updated.`,
                    }).then(() => {
                        this.assignData(response.data)
                        this.toggleEdit()
                        this.$Progress.finish()
                    })
                }
            })
        }
    },
} 
</script>
